<script setup lang="ts">
import {AssociativeArray} from "~/types/AssociativeArray";
import {BevcoProductPrice} from "~/types/BevcoProductPrice";
import {AlgoliaProduct} from "~/types/AlgoliaResult";
import {SharedProductSliderConfig} from "~/types/SharedProductSliderConfig";
import ProductCard from "~/components/product/ProductCard.vue";
import useAlgolia from "~/composables/useAlgolia";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

const props = defineProps<{
    products: AlgoliaProduct[] | undefined;
    config: SharedProductSliderConfig | undefined;
}>();

const {addSearchHistory, algoliaTrackClick} = useAlgolia();
const productPrices = inject("productPrices") as AssociativeArray<BevcoProductPrice>;
const displayGross = inject("displayGross") as boolean;
const productCarousel = ref(null);

const breakpoints = {
    768: {
        itemsToShow: 3
    },
    1024: {
        itemsToShow: 4
    },
    1536: {
        itemsToShow: 5
    },
};

const nextSlide = () => {
    productCarousel.value.next();
}

const prevSlide = () => {
    productCarousel.value.prev();
}

</script>

<template>
<div class="cms-element-product-slider carousel-element">
    <div class="flex mb-4">
        <span v-if="config?.title" class="text-[18px] md:text-[22px] font-semibold">
            {{ config?.title }}
        </span>
        <span class="pt-[2px]" v-if="config?.link">
            <span v-if="config.nbHits" class="text-brand-darkGray text-[18px] ml-2">({{ config.nbHits }})</span>
            <NuxtLink :href="config.link" class="border-b border-b-solid ml-4">{{ $t('listing.seeAll') }}</NuxtLink>
        </span>
    </div>
    <div v-if="products ? products.length > 0 : false" class="relative" :class="{ 'border border-gray-300': props.config?.border }">
        <carousel
            :items-to-show="2"
            :items-to-scroll="1"
            :wrap-around="true"
            :breakpoints="breakpoints"
            snap-align="start"
            ref="productCarousel"
        >
            <slide v-for="(product, index) of products" :key="product.objectID">
                <div
                    class="
                        carousel__item relative w-full h-full bg-white
                        p-[10px]
                        md:px-[15px]
                        lg:px-[20px]
                        xl:px-[25px] xl:pt-[10px] xl:pb-[15px]
                    "
                >
                    <ProductCard
                        class="h-full"
                        :product="product"
                        :layoutType="props.config?.layoutType"
                        :displayMode="'contain'"
                        :price="productPrices[product.objectID]"
                        :display-gross="displayGross"
                        @click="[
                            addSearchHistory(product.objectID, product.queryID ?? null),
                            algoliaTrackClick(product.objectID, 'ProductSlider: Product Clicked', product.queryID ?? null, product.queryID ? index+1 : null),
                        ]"
                    />
                </div>
            </slide>
        </carousel>
        <div class="slider-nav-prev" @click="prevSlide"><NuxtImg src="/arrow-left-bold.svg" width="7" height="13"/></div>
        <div class="slider-nav-next" @click="nextSlide"><NuxtImg src="/arrow-left-bold.svg" width="7" height="13" class="rotate-180"/></div>
    </div>
</div>
</template>
<style scoped>
.carousel-element .carousel {
    width: 100%;
}

.carousel__slide.carousel__slide--visible {
    @media screen and (min-width: 768px) {
        padding-right:12px;
        padding-left:12px;
    }

    padding-right:5px;
    padding-left:5px;
}
.slider-nav-prev, .slider-nav-next {
    display: none;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    width: 40px;
    box-shadow: 0px 3px 6px #00000029;

    position: absolute;
    top: calc(50% - 20px);

    @media screen and (min-width: 640px) {
        display: inline-block;
    }
}

.slider-nav-prev {
    left: 0px;
    padding: 10px 16px;
}

.slider-nav-next {
    right: 0px; /* Due to last slide item has padding right, we fake it with the nav button.*/
    padding: 10px 18px;
}
</style>
